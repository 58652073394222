@import 'base/global';

/* Required styles */

.scrollable-container {
  display: flex;
  align-items: flex-start;
}

.scrollable-section {
  width: 50%;
  overflow: hidden;
  position: sticky;
  top: 0;
}

.scrollable-section-inner {
  height: 100vh;
}

/* Demo styles */

html {
  font-size: 21px;
  font-weight: 400;
  line-height: 1.6;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: rgba(0, 0, 0, 0.6);
  background: rgb(231, 231, 231);
}

img {
  max-width: 100%;
  padding: 10px 25px;
  height: auto;
}

p {
  margin: 10px auto;
  padding: 5px 3vw;
  max-width: 700px;
}

strong {
  font-size: 35px;
  letter-spacing: 0px;
  line-height: 1;
  font-weight: 900;

  color: rgba(0, 0, 0, 0.85);
}

p:last-child {
  padding-bottom: 100px;
}

h1 {
  font-size: 20px;
  letter-spacing: 10px;
  text-transform: uppercase;
}

h3 {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 4px;

  margin: 30px auto 0;
  padding: 10px 50px;
  max-width: 750px;
}

blockquote {
  font-size: 2.5vw;
  letter-spacing: 1px;
  line-height: 1.25;
  font-weight: 900;
  font-style: italic;
  text-align: center;

  margin: 10vw auto;
  padding: 10px 125px;
  max-width: 750px;

  color: rgba(0, 0, 0, 0.85);
}

header,
footer {
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 80px;
}

header h1 {
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 100px 0;
}

.header-img {
  background-image: url('https://images.unsplash.com/photo-1541774995-47504d7c9f78?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80');
  background-size: cover;
  background-position: center center;
  height: 10vh;
  width: 100%;
}

.recipe {
  background: #fafafa;
  color: black;
  padding-bottom: 45vh;
  margin-top: 200px;
  padding-top: 100px;
  border: 0.5px solid white;
}

ol {
  margin: 20px auto;
  padding: 5px 50px 50px 25px;
  max-width: 600px;
  font-weight: bold;
}

li {
  margin: 10px 0;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 15px;
    line-height: 1.4;
  }

  p {
    padding: 10px;
  }

  img {
    padding: 0;
  }
}

.not-scrollable {
  min-height: 200vh;
  text-align: center;
  background: yellow;
}

.scrollable-section {
  opacity: 1;
  outline: solid 0.5px rgba(0, 0, 0, 0.05);
}

.scrollable-section-inner {
  margin: 100px 0 0;
}

.scrollable-section img {
  max-width: calc(100% - 200px);
  padding: 2px;
  margin: 100px;
  outline: 10px solid rgba(0, 0, 0, 0.85);
}

.one:hover,
.two:hover {
  opacity: 1;
}
